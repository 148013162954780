<template>
  <div class="container-fluid userheader-content">
    <div class="row">
      <!-- Logo Section -->
      <div class="col-12 col-md-3">
        <div class="h3-logo-wrapper"
             :class="subscription.class_name">
          <router-link to="/browse/main">
            <img class="h3-logo img-fluid"
                src="../../assets/images/h3_logo.png"
                alt="h3-logo" />
          </router-link>

          <router-link class="subscription-link" to="/subscription">
            <p class="subscription-name mb-0"
               title="Subscription">
              {{ subscription.name }}
            </p>
          </router-link>
        </div>
      </div>

      <!-- Search Section -->
      <div class="col-12 col-md-9 text-right align-self-center">
        <b-input-group :class="showSearchBar ? '' : 'd-flex'">
          <b-form-select class="form-control filter"
                         :options="options"
                         slot="prepend"
                         v-model="filter"
                         v-if="showSearchBar" />
          <input class="form-control search-input"
                 :type="showSearchBar ? '' : 'hidden'"
                 placeholder="Search"
                 v-model="searchText" />

          <span class="send-invite"
                :class="showSearchBar ? 'ml-2' : 'ml-auto'"
                title="Send Invite"
                @click="showSendInviteModal">
                <i class="fas fa-user-plus"></i>
              </span>
          <!-- Notification Bell -->
          <span class="notif-bell"
                @click="toggleNotifList"
                v-click-outside="hideNotifList"
                title="Notifications">
            <notification-bell
                :size="bellSize"
                :count="unreadCounter"
                :upperLimit="50"
                counterLocation="upperRight"
                counterStyle="roundRectangle"
                counterBackgroundColor="#FF0000"
                counterTextColor="#FFFFFF"
                iconColor="#068bcd"
                :animated="true"
            />
          </span>
        </b-input-group>
        <notification-list v-show="notifList"
                           :show="notifList"
                           @close-notif-list="toggleNotifList" />
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import ClickOutside from 'vue-click-outside';
  import NotificationBell from 'vue-notification-bell';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { mapActions, mapGetters } from 'vuex';
  const NotificationList = () =>
  import('@/components/notification/NotificationList.vue');
  const SendInvite = () =>
  import('@/components/modals/SendInvite.vue');
  

  export default {
    name   : 'UserHeader',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        searchText : null,
        keyword    : null,
        filter     : null,
        options    : [ {
          value    : null,
          text     : 'Filter By',
          disabled : true,
        }, {
          value : 'media',
          text  : 'Media Title',
        }, {
          value : 'category',
          text  : 'Category Title',
        } ],
        timeout   : null,
        notifList : false,
        bellSize  : 0,
      }
    },
    computed : {
      ...mapGetters({
        subscription : 'subscription/subscription',
      }),
      showSearchBar() {
        let show = false;
        if (this.$store.getters['user/user'].user_type !== 6)
          show = true;

        return show;
      },
      unreadCounter() {
        let unreadAdm = 0;
        let unreadAct = 0;

        if (this.$store.state.notification.adminListNotifs.to) {
          unreadAdm = this.$store.state.notification.adminListNotifs.to -
            this.$store.state.notification.adminListNotifs.readTotal;
        }

        if (this.$store.state.notification.activityNotifs.to) {
          unreadAct = this.$store.state.notification.activityNotifs.to -
            this.$store.state.notification.activityNotifs.readTotal;
        }

        return parseInt(unreadAdm + unreadAct);
      },
    },
    watch : {
      searchText(value) {
        if (value)
          this.search();
      },
      filter(value) {
        if (value)
          this.search();
      },
      '$route.path'() {
        this.notifList = false; // hide notif list

        if (this.$route.name !== 'search') {
          this.searchText = null;
          this.keyword = null;
          this.filter = null;
        }
      },
      windowWidth(value) {
        this.bellSize = 40;
        if (value < 420)
          this.bellSize = 30;
      },
    },
    components : {
      NotificationBell,
      NotificationList,
    },
    methods : {
      ...mapActions({
        'getAdminListNotifs' : 'notification/getAdminListNotifs',
        'getActivityNotifs'  : 'notification/getActivityNotifs',
      }),

      // eslint-disable-next-line no-undef
      search : _.debounce(function() {
        if (this.searchText && this.filter) {
          this.$router.push({
            path  : '/browse/search',
            query : {
              filter  : this.filter,
              keyword : this.searchText,
            },
          });
        } else if (this.searchText) {
          this.$notify({
            group : 'notif',
            type  : 'info',
            text  : 'Please select a filter.',
          });
        } else {
          this.$notify({
            group : 'notif',
            type  : 'info',
            text  : 'Please enter text to search.',
          });
        }
      }, 1000),

      /**
       * Display or Hide Notification Dropdown List
       */
      toggleNotifList () {
        this.notifList = !this.notifList;
        if (this.notifList === false) {
          this.markAllAdminAsRead();
          this.markAllActivityAsRead();
        }
      },

      /**
       * Hide Notif List
       */
      hideNotifList() {
        if (this.notifList === true) {
          this.notifList = false;
          this.markAllAdminAsRead();
          this.markAllActivityAsRead();
        }
      },

      /**
       * Opens Send Invite Modal
       */
      showSendInviteModal() {
        this.$modal.show(SendInvite, {
        }, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : false,
        });
      },

      /**
       * Mark All Admin Notifs as Read
       */
      markAllAdminAsRead() {
        if (this.$store.state.notification.adminListNotifs.to >
          this.$store.state.notification.adminListNotifs.readTotal) {
          this.$http.post('api/notification/admin/read/all', {
            adminNotifs :
              this.$store.getters['notification/adminListNotifs'].data,
            platform : 'website',
          }).finally(() => {
            this.getAdminListNotifs();
          });
        }
      },

      /**
       * Mark All Activity Notifs as Read
       */
      markAllActivityAsRead() {
        if (this.$store.state.notification.activityNotifs.to >
          this.$store.state.notification.activityNotifs.readTotal) {
          this.$http.post('api/notification/activity/read/all')
            .then(() => {
              this.getActivityNotifs();
            });
        }
      },
    },
    created() {
      this.filter = this.$route.query.filter || null;
      this.searchText = this.$route.query.keyword || null;
    },
    mounted() {
      document
        .querySelectorAll('.notification svg title')[0]
        .innerHTML = 'Notifications';

      // Get Subscription data
      this.$store.dispatch('subscription/getSubscription');
    },
    directives : {
      ClickOutside,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/user-header";
</style>
